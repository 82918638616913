import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

/**
 * Props for `StatsMedia`.
 */
export type StatsMediaProps = SliceComponentProps<Content.StatsMediaSlice>;

/**
 * Component for "StatsMedia" Slices.
 */
const StatsMedia = ({ slice }: StatsMediaProps): JSX.Element => {
  const { items, primary } = slice;
  const { background_color, heading, description, image } = primary;
  const [bg, setBg] = useState(background_color);

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background={background_color}
      className={clsx(
        'wide-container color-changing-section',
        '[ transition-color ease-in-out duration-1500 ][ w-full ][ !pt-0 ][ relative ]',
        {
          '[ bg-gray-97 ]': bg === 'light',
          '[ bg-gray-0 ]': bg === 'dark',
        },
      )}
    >
      <div className="[ px-5 md:px-16 lg:px-44 py-8 md:py-10 lg:py-24 ][ w-full ]">
        <div className="[ text-center ][ flex flex-col items-center ][ w-full max-w-4xl ][ mx-auto mb-10 md:mb-20 ]">
          <h2
            className={clsx(
              '[ text-26 md:text-56 font-medium leading-snug ][ max-w-2xl ]',
              {
                '[ text-white ]': bg === 'dark',
              },
            )}
          >
            {heading}
          </h2>

          <h4
            className={clsx(
              '[ text-14 md:text-20 ]',
              {
                '[ text-gray-60 ]': bg === 'dark',
                '[ text-gray-40 ]': bg !== 'dark',
              }
            )}
          >
            {description}
            </h4>
        </div>

        <div className="[ flex flex-col md:flex-row justify-around items-center ]">
          <PrismicNextImage field={image} className="[ w-1/2 max-w-xl h-full ][ mb-10 md:mb-0 ]" />

          <div className="[ flex flex-row md:flex-col justify-start md:justify-evenly flex-wrap md:flex-nowrap gap-5 md:gap-0 ][ min-w-44 md:min-w-0 ][ space-y-0 md:space-y-10 ]">
            {items.map(item => (
              <div className="[ flex flex-col ]" key={item.stats_text}>
                <span
                  className={clsx(
                    '[ text-34 md:text-60 lg:text-80 font-semibold md:font-normal leading-snug ]',
                    {
                      '[ text-gray-97 ]': bg === 'dark',
                    },
                  )}
                >
                  {item.stats_text}
                </span>

                <span
                  className={clsx(
                    '[ text-14 md:text-18 lg:text-20 ]',
                    {
                      '[ text-gray-60 ]': bg === 'dark',
                      'text-gray-40': bg !== 'dark',
                    },
                  )}
                >
                  {item.stats_description}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsMedia;
